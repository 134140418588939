// import Particles from "./Particles";
import { Container } from "./styles";
import { Hero } from "../Hero/Hero";
import { About } from "../About/About";
import { Contact } from "../Contact/Contact";
import MySkills from "../MySkills/MySkills";
import MyLanguages from "../MyLanguages/MyLanguages";
import MyWorks from "../MyWorks/MyWorks";
import Experience from "../Experience/Experience";
import Certificates from "../Certificates/Certificates";
import Tools from "../Tools/Tools";

export function Main() {
  return (
    <Container>
      {/* <Particles /> */}
      <Hero />
      <About />
      <MyWorks />
      <Tools />
      <div className="section-row">
        <div style={{ flex: 1 }}>
          <MyLanguages />
        </div>
        <div style={{ flex: 1 }}>
          <MySkills />
        </div>
      </div>
      <Certificates />
      <Experience />
      <Contact />
    </Container>
  );
}
